// global stores
import axios from 'axios';
import { utilityMutations } from '../../../../../Core/Global/Store/utility';
import {
  formProcessingActions,
  formProcessingGetters,
  formProcessingMutations,
  formProcessingState,
} from '../../../../../Core/Global/Store/form-processing';

export const state = {
  ...formProcessingState,
  formId: '#register',
  formAction: 'Add',
  activeRecord: {
    _token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    name: '',
    username: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    password: '',
    password_confirmation: '',
  },
  errorMessage: null,
  shouldRedirect: true,
  success: false,
};

export const getters = {
  ...formProcessingGetters,
  getActiveRecord: (state) => state.activeRecord,
  getFormId: (state) => state.formId,
};

export const mutations = {
  ...utilityMutations,
  ...formProcessingMutations,
  SET_ACTIVE_RECORD(state, record) {
    state.activeRecord = record;
  },
  SET_ERROR_MESSAGE(state, value) {
    state.errorMessage = value;
  },
  SET(state, [key, value]) {
    state[key] = value;
  },

};

export const actions = {
  ...formProcessingActions,
  submitForm({
    commit, getters, dispatch, state,
  }) {
    const formId = getters.getFormId;
    const formData = getters.getActiveRecord;

    // check if the form is valid
    dispatch('validateForm', formId);

    if (getters.getFormIsValid) {
      dispatch('setFormProcessing', true);

      axios
        .post('/register', formData)
        .then((response) => {
          commit('SET', ['success', true]);
          let fromLPC = (new URLSearchParams(window.location.search)).get('lpc') !== null
          window.location.href = fromLPC ? '/locke-planning-center' : '/dashboard'
        })
        .catch((error) => {
          // set the form processing variable
          dispatch('setFormProcessing', false);

          if (error.request) {
            console.log(error.request);
            if (error.request.status == 422) {
              const data = JSON.parse(error.request.responseText);

              const errorMessage = data.message;
              let formErrors = '';
              formErrors += `<p>${errorMessage}<p>`;
              const errorsUl = document.createElement('ul');
              errorsUl.classList.add('list-unstyled');
              let i = 0;
              for (const error in data) {
                if (i > 0) {
                  const message = data[error];
                  for (const field in message) {
                    console.log(`${field}: ${message[field]}`);
                    errorsUl.innerHTML += `<li>${message[field]}</li>`;
                  }
                }
                i++;
              }
              formErrors += errorsUl.innerHTML;
              commit('SET_ERROR_MESSAGE', formErrors);
            } else {
              console.log(error);
            }
          } else {
            console.log(error);
          }
        });
    }
  },
};

export default {
  namespaced: true, state, getters, mutations, actions,
};
