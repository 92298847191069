import Vuex from 'vuex';
import Vue from 'vue';
//modules
import register from './modules/register';
import login from './modules/login';
import reset from './modules/reset';
import forgot from './modules/forgot';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
      register,
      login,
      forgot,
      reset

    }
});