import { utilityMutations } from '@v/Core/Global/Store/utility';

import { coreModalActions, coreModalMutations, coreModalState } from '@v/Core/Global/Store/core-modal';
import {
  formProcessingActions, formProcessingGetters, formProcessingMutations, formProcessingState,
} from '@v/Core/Global/Store/form-processing';

export const state = {
  ...coreModalState,
  ...formProcessingState,
  formId: '#login-form',
  activeRecord: {
    _token: document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content'),
    email: '',
    password: '',
  },
  shouldRedirect: false,
  errorMessage: null,
  successMessage: null,
};

export const getters = {
  ...formProcessingGetters,
  getActiveRecord: (state) => state.activeRecord,
  getFormId: (state) => state.formId,
};

export const mutations = {
  ...utilityMutations,
  ...formProcessingMutations,
  ...coreModalMutations,
  SET_ACTIVE_RECORD(state, record) {
    state.activeRecord = record;
  },
  SET_ERROR_MESSAGE(state, value) {
    state.errorMessage = value;
  },
  SET(state, [key, value]) {
    state[key] = value;
  },

};

export const actions = {
  ...formProcessingActions,
  ...coreModalActions,
  submitForm({
    commit, getters, dispatch, state,
  }, { id, path }) {
    const formId = getters.getFormId;
    const formData = getters.getActiveRecord;
    const url = path;

    // check if the form is valid
    dispatch('validateForm', formId);

    if (getters.getFormIsValid) {
      dispatch('setFormProcessing', true);
      axios
        .post('/login', formData, {headers: {'X-CSRF-TOKEN': document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')}})
        .then((response) => {
          dispatch('setFormProcessing', false);
          commit('SET_ACTIVE_RECORD', null);
          commit('SET', ['successMessage', 'Success!']);

          const fromLPC = (new URLSearchParams(window.location.search)).get('lpc') !== null;
          window.location.href = fromLPC ? '/locke-planning-center' : '/dashboard';

          return true;
        })
        .catch((error) => {
          // set the form processing variable
          dispatch('setFormProcessing', false);

          if (error.request) {
            console.log(error.request);
            if (error.request.status == 422) {
              const data = JSON.parse(error.request.responseText);

              const errorMessage = data.message;
              let formErrors = '';
              formErrors += `<p>${errorMessage}<p>`;
              const errorsUl = document.createElement('ul');
              errorsUl.classList.add('list-unstyled');
              let i = 0;
              for (const error in data) {
                if (i > 0) {
                  const message = data[error];
                  for (const field in message) {
                    console.log(
                      `${field}: ${message[field]}`,
                    );
                    errorsUl.innerHTML += `<li>${message[field]}</li>`;
                  }
                }
                i++;
              }
              formErrors += errorsUl.innerHTML;
              commit('SET_ERROR_MESSAGE', formErrors);
            } else {
              console.log(error);
            }
          } else {
            console.log(error);
          }
        });
    }
  },

};

export default {
  namespaced: true, state, getters, mutations, actions,
};
