import { utilityMutations } from "@v/Core/Global/Store/utility";

import { formProcessingActions,formProcessingGetters,formProcessingMutations,formProcessingState
} from "@v/Core/Global/Store/form-processing";

export const state = {
    ...formProcessingState,
    formId: "#reset-form",
    activeRecord: {
            token: document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
            password: '',
            password_confirmation: '',
    },
    errorMessage: null,
    successMessage:null
};

export const getters = {
    ...formProcessingGetters,
    getActiveRecord: (state) => {
        return state.activeRecord;
    },
    getFormId: (state) => {
        return state.formId;
    },
};

export const mutations = {
    ...utilityMutations,
    ...formProcessingMutations,
    SET_ACTIVE_RECORD(state, record) {
        state.activeRecord = record;
    },
    SET_ERROR_MESSAGE(state, value) {
        state.errorMessage = value;
    },
    SET_SUCCESS_MESSAGE(state, value) {
        state.successMessage = value;
    },
};

export const actions = {
    ...formProcessingActions,
    submitForm({ commit, getters, dispatch }) {
        const formId = getters.getFormId;
        const formData = getters.getActiveRecord;

        //check if the form is valid
        dispatch("validateForm", formId);

        if (getters.getFormIsValid) {
            dispatch("setFormProcessing", true);
            axios
                .post("/reset-password", formData)
                .then((response) => {

                    commit('SET_SUCCESS_MESSAGE','Sucesss! Your password has been changed.')

                })
                .catch((error) => {
                    //set the form processing variable
                    dispatch("setFormProcessing", false);

                    if (error.request) {
                        console.log(error.request);
                        if (error.request.status == 422) {
                            const data = JSON.parse(error.request.responseText);

                            const errorMessage = data.message;
                            let formErrors = "";
                            formErrors += `<p>${errorMessage}<p>`;
                            let errorsUl = document.createElement("ul");
                            errorsUl.classList.add("list-unstyled");
                            let i = 0;
                            for (const error in data) {
                                if (i > 0) {
                                    let message = data[error];
                                    for (const field in message) {
                                        console.log(
                                            `${field}: ${message[field]}`
                                        );
                                        errorsUl.innerHTML += `<li>${message[field]}</li>`;
                                    }
                                }
                                i++;
                            }
                            formErrors += errorsUl.innerHTML;
                            commit("SET_ERROR_MESSAGE", formErrors);
                        } else {
                            console.log(error);
                        }
                    } else {
                        console.log(error);
                    }
                });
        }
    },
};

export default { namespaced: true, state, getters, mutations, actions };
