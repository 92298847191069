export const coreModalState = {
    isModalOpen: false,
    isModalProcessing: false
}

export const coreModalMutations = {
    SET_MODAL_OPEN(state, value){
        state.isModalOpen = value;
    },
    SET_MODAL_PROCESSING(state, value){
        state.isModalProcessing = value;
    }
}

export const coreModalActions = {
    closeModal({ commit }){
        commit('SET_MODAL_OPEN', false);
    },
    openModal({ commit }){
        commit('SET_MODAL_OPEN', true);
    }
}