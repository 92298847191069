import { utilityMutations } from "@v/Core/Global/Store/utility";
import { coreModalActions, coreModalMutations, coreModalState } from "@v/Core/Global/Store/core-modal";
import { formProcessingActions, formProcessingGetters, formProcessingMutations, formProcessingState } from "@v/Core/Global/Store/form-processing";

export const state = {
    ...formProcessingState,
    ...coreModalState,
    formId: "#forgot-form",
    activeRecord: {
        _token: document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        email: '',
    },
    errorMessage: null,
    successMessage: null
};

export const getters = {
    ...formProcessingGetters,
    getActiveRecord: (state) => {
        return state.activeRecord;
    },
    getFormId: (state) => {
        return state.formId;
    },
};

export const mutations = {
    ...utilityMutations,
    ...formProcessingMutations,
    ...coreModalMutations,
    SET_ACTIVE_RECORD(state, record) {
        state.activeRecord = record;
    },
    SET_ERROR_MESSAGE(state, value) {
        state.errorMessage = value;
    },
    SET_SUCCESS_MESSAGE(state, value) {

        state.successMessage = value;
    }
}

export const actions = {
    ...formProcessingActions,
    ...coreModalActions,
    forgot({ commit, getters, dispatch }, {id,path}){
        const formId = getters.getFormId;
        const formData = getters.getActiveRecord;

        //check if the form is valid
        dispatch("validateForm", "#forgot-form");

        if (getters.getFormIsValid) {
            dispatch("setFormProcessing", true);
            axios
                .post('/forgot-password', formData)
                .then((response) => {
                    dispatch('setFormProcessing', false);
                    dispatch('resetForm');
                    commit("SET_SUCCESS_MESSAGE","Succcess! We sent you a link to reset your password.");

                }).catch((error) => {
                    //set the form processing variable
                    dispatch("setFormProcessing", false);

                    // No error.request or status is not 422, log and break
                    if (!error.request || error.request.status !== 422) { console.error(error); return; }

                    // If error.request && status is 422, continue on
                    let data           = JSON.parse(error.request.responseText),
                        formErrors     = `<p>${data.message}<p>`,
                        errorsUl       = document.createElement("ul");

                    errorsUl.classList.add("list-unstyled");

                    let i = 0;
                    for (const error in data) {
                        if (i > 0) {
                            let message = data[error];
                            for (const field in message) {
                                console.error(
                                    `${field}: ${message[field]}`
                                );
                                errorsUl.innerHTML += `<li>${message[field]}</li>`;
                            }
                        }
                        i++;
                    }
                    formErrors += errorsUl.innerHTML;
                    commit("SET_ERROR_MESSAGE", formErrors);
                });
        }

    },
    resetForm({commit}){

        let ar = {
            _token: document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
            email: '',
        }

        commit("SET_ACTIVE_RECORD", ar);

    }

};

export default { namespaced: true, state, getters, mutations, actions };
